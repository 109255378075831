import { ChangeEvent, useEffect, useState } from "react";
import { Button, ErrorField, FullModalStyled, Htag } from "components";
import { Box, Checkbox, FormControlLabel } from "@mui/material";

import s from "./AtlaAI.module.css";

import Chatbot from "./ChatBot/ChatBot.component";
import { useNavigate } from "react-router-dom";

const style = {
  transition: "all 0.2s ease",

  "*::-webkit-scrollbar": {
    width: ".01em",
  },
  "*::-webkit-scrollbar-track": {
    // "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    borderRadius: "10px",
    backgroundColor: "rgba(0,0,0,0)",
  },
  "*::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,0)",
    outline: "0px solid slategrey",
    borderRadius: "10px",
  },
};

export const AtlaAIPageComponent = (): JSX.Element => {
  const [agreegment, setAgreegment] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [formError, setFormError] = useState("");

  const navigate = useNavigate();

  const [openChatBot, setOpenChatBot] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!formError) {
      if (agreegment) {
        setFormValid(true);
        setOpenChatBot(true);
      } else {
        setFormError(
          "To proceed you need to confirm you understand Atla AI is a chatbot which doesn’t offer legal advice."
        );
        setFormValid(false);
      }
    }
  };

  const handleCloseChatBot = () => {
    setOpenChatBot(false);
    navigate("/dashboard");
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!agreegment) {
      setFormError("");
      setAgreegment(e.target.checked);
    }
    {
      setAgreegment(e.target.checked);
    }
  };

  if (!formValid) {
    return (
      <>
        <Htag tag="h2">Welcome to Atla AI</Htag>
        <p>
          Atla AI is a chatbot designed to help with divorce in England and
          Wales.
        </p>
        <p>
          It’s not a real person or a lawyer. It can help guide you through the
          divorce process, offer tips on practicalities, supporting children and
          handling finances, as well as point you to useful templates and
          resources.
        </p>
        <p>Remember:</p>
        <ul className="ul">
          <li>It can't give legal advice.</li>
          <li>
            It’s still learning and it’s information might not be 100% accurate
            or fit your specific situation. Please double check the answer by
            reading the source material linked.
          </li>
        </ul>
        <p>
          If at any point you are uncertain about the way forward, you should
          obtain professional or specialist advice from independent legal, tax,
          accounting or financial advisors before taking or refraining from any
          action.
        </p>
        <form className={s.agreementForm} onSubmit={handleSubmit}>
          <FormControlLabel
            control={<Checkbox checked={agreegment} onChange={handleChange} />}
            label="I understand Atla AI is a chatbot which doesn’t offer legal advice"
          />
          {formError && <ErrorField error={formError} />}
          <Button type="submit" apearance="dark">
            Continue
          </Button>
        </form>
      </>
    );
  } else {
    return (
      <FullModalStyled
        color="light"
        open={openChatBot}
        close={handleCloseChatBot}
      >
        <div className={s.atlaAIWrapper}>
          <Chatbot />
        </div>
      </FullModalStyled>
    );
  }
};
