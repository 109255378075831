import { createRef, forwardRef, useEffect, useRef, useState } from "react";

import "./ChatBot.module.css";
import { IChatAnswer, IChatPrompt } from "store/atla/atla-interface";
import { Button, Htag } from "components";
import { MessageComponent } from "components/Message/Message.component";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { atlaSendPrompt } from "store/atla/atla-thunk";
import { setUserMessage } from "store/atla/atla-reducer";
import { ThreeDots } from "react-loader-spinner";
import s from "./ChatBot.module.css";
import ReactHtmlParser from "html-react-parser";

const static_questions = [
  "What legal steps do I need to do?",
  "How do I divide finances?",
  "Which bits do I need a lawyer for?",
];

const AlwaysScrollToBottom = () => {
  const elementRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (elementRef.current) elementRef.current.scrollIntoView();
  });
  return <div ref={elementRef} />;
};
const Chatbot = () => {
  const [input, setInput] = useState<string>("");

  const formRef = useRef<HTMLFormElement>(null);

  const dispatch = useAppDispatch();

  const { messages, loading } = useAppSelector((state) => state.IAtlaState);

  const chatWithGPT3 = async (userInput: string) => {
    const data = {
      message: userInput,
    };

    const answer = dispatch(atlaSendPrompt(data))
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return {
          response: "",
        };
      });

    return answer;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!input.trim()) return;

    const userMessage: IChatPrompt = {
      prompt: input,
      type: "user",
    };

    dispatch(setUserMessage(userMessage));
    chatWithGPT3(input);

    setInput("");
  };

  const handleSendPrompt = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    str: string
  ) => {
    setInput(str);
    if (formRef.current) {
      setTimeout(() => {
        const event = new Event("submit", { bubbles: true, cancelable: true });
        formRef.current?.dispatchEvent(event);
      }, 0);
    }
  };

  return (
    <div className={s.chatbot_container}>
      {messages.length == 0 ? (
        <div>
          <Htag tag="h2">Ask Atla AI</Htag>
          <Htag tag="h3">Or try a starter question</Htag>
          <div className="">
            {" "}
            {static_questions.map((question, index) => (
              <MessageComponent
                key={index}
                onClick={(e) => handleSendPrompt(e, question)}
              >
                {question}
              </MessageComponent>
            ))}
          </div>
        </div>
      ) : (
        <div className={s.chatbot_messages}>
          {messages.map((message: IChatAnswer | IChatPrompt, index: number) => (
            <MessageComponent
              key={index}
              className={`${
                message.type == "user" ? "user-message" : "ai-message"
              }`}
            >
              <Htag tag="h4">
                {message.type == "user" ? "You:" : "AtlaAI:"}
              </Htag>
              <pre className="pre">
                {message.type == "user"
                  ? message.prompt
                  : message.answer.response}
              </pre>
            </MessageComponent>
          ))}
          {loading && (
            <MessageComponent>
              <Htag tag="h4">AtlaAI: </Htag>
              <ThreeDots />
            </MessageComponent>
          )}
        </div>
      )}
      <form
        className={s.chatbot_input_form}
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <Htag tag="h5">Ask Atla AI</Htag>
        <div className={s.formField}>
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className={s.chatBot_request_field}
          />
          <Button apearance="secondary" type="submit">
            Ask
          </Button>
        </div>
        <p className="centred">
          <strong>My answers aren't legal advice.</strong>
        </p>
      </form>
      <AlwaysScrollToBottom />
    </div>
  );
};
export default Chatbot;
