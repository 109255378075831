import { NavLink } from "react-router-dom";
import s from "./Navigation.module.css";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { resetToDefault } from "store/login/login-reducer";
import { DashboardNavI } from "./Navigation.props";

import cn from "classnames";
import { logoutAction } from "store/login/login-thunk";

export const NavigationComponent = ({
  nav,
  className,
  ...props
}: DashboardNavI): JSX.Element => {
  const dispatch = useAppDispatch();
  const has_children = useAppSelector(
    (state) =>
      state.userState.user.questionnaires.generalQuestionnary?.has_children
  );
  const signOut = () => {
    dispatch(logoutAction());
    localStorage.removeItem("userToken");
    dispatch(resetToDefault());
  };
  return (
    <ul className={cn(s.nav_list, className)} {...props}>
      {nav.map((route, index) =>
        route.menuProps
          ? route.child
            ? route.child.map(
                (item, index) =>
                  item.menuProps &&
                  item.path &&
                  (item.menuProps?.displayText == "Children" &&
                  has_children == "no" ? (
                    ""
                  ) : (
                    <li key={index}>
                      <NavLink
                        className={({ isActive }) =>
                          [
                            "hover-underline-animation_back",
                            isActive ? s.active : s.inactive,
                          ]
                            .filter(Boolean)
                            .join(" ")
                        }
                        to={item.url || item.path}
                      >
                        {item.menuProps.displayText}
                      </NavLink>
                    </li>
                  ))
              )
            : route.menuProps &&
              route.path && (
                <li key={index}>
                  <NavLink
                    className={({ isActive }) =>
                      [
                        "hover-underline-animation_back",
                        isActive ? s.active : s.inactive,
                      ]
                        .filter(Boolean)
                        .join(" ")
                    }
                    to={route.url || route.path}
                  >
                    {route.menuProps.displayText}{" "}
                  </NavLink>
                </li>
              )
          : null
      )}
      <li onClick={signOut}>
        <NavLink className={"hover-underline-animation_back"} to="/">
          Log out
        </NavLink>
      </li>
    </ul>
  );
};
