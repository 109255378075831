import dayjs from "dayjs";
import { IgetUsersApiRequest } from "store/waitingList/waitingList.interface";

export const getDots = (str: string | undefined, count: number) => {
  if (!str || !str.length) return "";

  return str.length > count ? "..." : "";
};

export const formData = (values: Record<string, any>): FormData => {
  const formData = new FormData();

  const processValues = (data: Record<string, any>, prefix = "") => {
    for (const key in data) {
      const value = data[key];
      const keyPrefix = prefix ? `${prefix}[${key}]` : key;
      if (value instanceof Blob || value instanceof File) {
        formData.append(keyPrefix, value);
      } else if (Array.isArray(value)) {
        value.forEach((item: any, index: number) => {
          if (typeof item === "object" && item !== null) {
            processValues(item, `${keyPrefix}[${index}]`);
          } else {
            formData.append(`${keyPrefix}[${index}]`, item);
          }
        });
      } else if (typeof value === "object" && value !== null) {
        processValues(value, keyPrefix);
      } else {
        formData.append(keyPrefix, value);
      }
    }
  };

  processValues(values);

  return formData;
};

export const sortArray = (array: string[]) => {
  return array.sort((a, b) => {
    const A = +a.replace(/\D/g, "");
    const B = +b.replace(/\D/g, "");
    if (A - B > 0) {
      return 1;
    } else {
      return -1;
    }
  });
};

export const getTimeCreate = (updateTime: string | undefined): string => {
  dayjs().calendar(null, {
    sameDay: "[Today at] h:mm A", // The same day ( Today at 2:30 AM )
    nextDay: "[Tomorrow at] h:mm A", // The next day ( Tomorrow at 2:30 AM )
    nextWeek: "dddd [at] h:mm A", // The next week ( Sunday at 2:30 AM )
    lastDay: "[Yesterday at] h:mm A", // The day before ( Yesterday at 2:30 AM )
    lastWeek: "[Last] dddd [at] h:mm A", // Last week ( Last Monday at 2:30 AM )
    sameElse: "DD/MM/YYYY", // Everything else ( 17/10/2011 )
  });

  return dayjs().calendar(dayjs(updateTime));
};

export const getQueryString = (
  str: string,
  params: IgetUsersApiRequest
): string => {
  const { filter, sort } = params;

  if (filter && filter.length) {
    filter.map(
      (item) => (str = str.concat("&", `search[${item.field}]=${item.value}`))
    );
  }

  if (sort)
    str = str.concat("&", `sort[field]=${sort.field}&sort[sort]=${sort.sort}`);
  return str;
};

export const stringToDate = (dateString: string): Date => {
  return new Date(dateString.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
};

export const getTypeArticle = (
  type: string
): "" | "Guide" | "Wellbeing" | "Task" | "Case Study" => {
  if (type && type.includes("resource")) return "Guide";
  if (type.includes("resource")) return "Guide";
  if (type.includes("case")) return "Case Study";
  if (type.includes("tool")) return "Wellbeing";
  if (type.includes("ticket")) return "Task";
  return "";
};
