import { createAsyncThunk } from '@reduxjs/toolkit';
import { ATLABOTAPI } from 'store/config';
import { IInitState } from 'store/root-interface';
import { IAtlaResponse } from './atla-interface';

export const atlaSendPrompt = createAsyncThunk<
  IAtlaResponse,
  { message: string },
  { getState: IInitState; rejectValue: string }
>(
  "@@atla/sendPrompt",
  async function (data, { getState, rejectWithValue, dispatch }) {
    const header = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(ATLABOTAPI(), header);
	console.log(response);
    if (!response.ok) {
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.errors);
        });
    } else {
      return (await response.json()) as IAtlaResponse;
    }
  }
);
