import { Link, NavLink } from "react-router-dom";
import { AccountIcon, Button, FullModalStyled, ModalStyled } from "components";

import styles from "./Nav.module.css";
import { NavProps } from "./Nav.props";
import cn from "classnames";
import { useEffect, useState } from "react";

import { Login } from "../../auth/Login/Login.component";
import menu from "mock/navigation.json";
import { OnBoardingComponent } from "../../auth/Registration/OnBoarding.component";
import { ForgotPass } from "../../auth/ForgotPass/ForgotPass.component";
import {
  openLoginModal,
  openPassModal,
  resetToDefault,
} from "store/login/login-reducer";
import {
  setInterviewToInit,
  setRegModalStatus,
} from "store/onBoarding/onboarding-reducer";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import dayjs from "dayjs";
import { logoutAction } from "store/login/login-thunk";

export const TopNav = ({ className, ...props }: NavProps): JSX.Element => {
  const userToken = useAppSelector((state) => state.userState.token);
  const loginModalStatus = useAppSelector(
    (state) => state.onLogin.openLoginModal
  );
  const passwordModalStatus = useAppSelector(
    (state) => state.onLogin.openPassModal
  );
  const regModalStatus = useAppSelector(
    (state) => state.onRegistration.openModal
  );

  const role = useAppSelector((state) => state.userState.user.role);
  let dashboardUrl = "/dashboard";
  if (role == "admin") {
    dashboardUrl = "/admin-dashboard";
  }
  const [entrance, setEntrance] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const expired_date = useAppSelector((state) => state.userState.expired_at);
  const handleClick = () => {
    setEntrance((entrance) => !entrance);
  };

  const [diff, setDiff] = useState<number>();

  // REGISTRATION //

  const handleOpenReg = () => dispatch(setRegModalStatus(true));
  const handleCloseReg = () => {
    dispatch(setInterviewToInit());
    dispatch(setRegModalStatus(false));
    dispatch(resetToDefault());
    setEntrance(false);
  };

  // LOGIN //

  const handleOpenLogin = () => dispatch(openLoginModal(true));
  const handleClose = () => {
    dispatch(openLoginModal(false));
    dispatch(resetToDefault());
  };
  const dispatch = useAppDispatch();

  const signOut = () => {
    dispatch(logoutAction());
    localStorage.removeItem("userToken");
    dispatch(resetToDefault());
  };

  // Forgot Pass

  const handleClosePass = () => {
    dispatch(openPassModal(false));
    dispatch(resetToDefault());
  };

  useEffect(() => {
    if (expired_date) {
      const diference = dayjs(expired_date).diff(dayjs(Date.now()));
      setDiff(diference);

      if (diference !== undefined && diference < 0) {
        dispatch(openLoginModal(true));
        signOut();
      } else {
        const sInterval = setInterval(() => {
          dispatch(openLoginModal(true));
          signOut();
          clearInterval(sInterval);
        }, diference);
      }
    }
  }, []);

  return (
    <>
      <nav className={cn(className, styles.nav)} {...props}>
        <input
          className={styles.checkbox}
          type="checkbox"
          checked={openNav}
          onChange={() => {
            setOpenNav((openNav) => !openNav);
            setEntrance(false);
          }}
          name=""
          id=""
        />
        <ul className={styles.menu}>
          {menu.map((rItem, i) => (
            <li key={i} className={styles.menuItem}>
              <NavLink
                className={cn(
                  "hover-underline-animation_back",
                  ({ isActive }: any) =>
                    isActive ? styles.active : styles.inactive
                )}
                to={rItem.to}
                onClick={() => setOpenNav(false)}
              >
                {rItem.text}
              </NavLink>
            </li>
          ))}
        </ul>
        <div
          className={cn(styles.entrances, {
            [styles.mobile_show]: entrance === true,
          })}
        >
          {!userToken ? (
            <div className={styles.nav_logout_icons}>
              <Button onClick={handleOpenLogin} apearance="default">
                Log in
              </Button>
              <Button onClick={handleOpenReg} apearance="secondary">
                Sign up
              </Button>
            </div>
          ) : (
            <div className={styles.nav_logout_icons}>
              <Link to={dashboardUrl}>
                <AccountIcon />
              </Link>

              <Button onClick={signOut} apearance="secondary">
                Log out
              </Button>
            </div>
          )}
        </div>

        <AccountIcon onClick={handleClick} className={styles.mobile_entrance} />

        <div className={styles.hamburger_lines}>
          <span className={cn(styles.line, styles.line1)}></span>
          <span className={cn(styles.line, styles.line2)}></span>
          <span className={cn(styles.line, styles.line3)}></span>
        </div>
      </nav>

      <FullModalStyled
        open={loginModalStatus}
        color="light"
        close={handleClose}
      >
        <Login />
      </FullModalStyled>
      <FullModalStyled
        open={regModalStatus}
        close={handleCloseReg}
        color="light"
      >
        <OnBoardingComponent />
      </FullModalStyled>
      <FullModalStyled
        open={passwordModalStatus}
        close={handleClosePass}
        color="light"
      >
        <ForgotPass />
      </FullModalStyled>
    </>
  );
};
