import {
  Alert,
  FormControl,
  MenuItem,
  Snackbar,
  TextField,
} from "@mui/material";

import { useEffect, useState } from "react";
import {
  Button,
  DropDownArrowIcon,
  EditorComponent,
  ErrorField,
  Htag,
  InputFileComponent,
} from "components";
import s from "../SpecialSources.module.css";
import { NavLink, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils/hooks";

import {
  get_system_single_ticket,
  update_system_ticket,
} from "store/system_tickets/system-ticket-thunk";
import { useFormik } from "formik";
import { validationCreateSchemaTicket } from "../validation";
import {
  FileProps,
  IAttach,
} from "store/system_resources/system_resource.interface";
import { milestones } from 'store/milestones/milestones-interface';



export const EditSTPage = () => {
  const { id } = useParams();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const dispatch = useAppDispatch();
  const [milestone, setMilestone] = useState(1);

  const [uniqueNumber, setUniqueNumber] = useState("");
  const [currentAttachments, setAttachments] = useState<Partial<IAttach>[]>([]);
  const [attachmentsOld, setOldAttachments] = useState<Partial<FileProps>[]>(
    []
  );

  const system_ticket = useAppSelector((state) =>
    state.specialTicketsState.tickets_db.filter(
      (ticket) => ticket.id == Number(id)
    )
  )[0];

  const { error } = useAppSelector((state) => state.specialTicketsState);

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      reading_time: "",
      timeline_id: milestone,
      attachments: [],
    },
    validationSchema: validationCreateSchemaTicket,
    onSubmit: (values) => {
      if (!id) return;

      const updatedValue =
        system_ticket.space_id == 1
          ? {
              title: values.title,
              description: values.description,
              milestone_id: milestone,
              reading_time: values.reading_time,
              attachments: currentAttachments,
            }
          : {
              title: values.title,
              description: values.description,
              reading_time: values.reading_time,
              attachments: currentAttachments,
            };
      dispatch(
        update_system_ticket({
          id: id,
          data: updatedValue,
        })
      )
        .unwrap()
        .then(() => {
          setOpenSnackbar(true);
          setAttachments([]);
        });
    },
  });

  useEffect(() => {
    if (!id) return;
    if (
      !system_ticket ||
      !Object.prototype.hasOwnProperty.call(system_ticket, "description")
    ) {
      dispatch(get_system_single_ticket(Number(id)))
        .unwrap()
        .then((res) => {
          setOldAttachments(res.attachments);
          if (res.milestone_id) setMilestone(res.milestone_id);
          setUniqueNumber(res.unique_number);
          formik.setFieldValue("description", res.description);
          formik.setFieldValue("title", res.title);
          formik.setFieldValue("reading_time", res.reading_time || "");
        })
        .catch((error: any) => {
          console.log(error);
        });
    } else {
      setOldAttachments(system_ticket.attachments);
      if (system_ticket.space_id == 1) setMilestone(system_ticket.milestone_id);

      setUniqueNumber(system_ticket.unique_number);
      formik.setFieldValue("description", system_ticket.description);
      formik.setFieldValue("title", system_ticket.title);
      formik.setFieldValue("reading_time", system_ticket.reading_time || "");
    }
  }, [system_ticket, id]);

  const handleStatusChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMilestone(Number(e.target.value));
  };

  const handleAtachments = (attachments: FileProps | IAttach) => {
    setAttachments((old) => [...old, attachments]);

    formik.setFieldValue("attachments", [...currentAttachments, attachments]);
    console.log(formik.values.attachments);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <>
      <div className={s.post_header}>
        <Htag tag="h3">Edit ticket: "{formik.values.title}" </Htag>
        <Htag tag="h4">Unique number: {uniqueNumber} </Htag>
      </div>

      <form onSubmit={formik.handleSubmit} className={s.formsWrap}>
        <div className={s.formField}>
          <Htag tag="h4">Title</Htag>
          <FormControl fullWidth className={s.title}>
            <input
              id="title"
              name="title"
              onBlur={formik.handleBlur}
              value={formik.values.title}
              type="text"
              onChange={formik.handleChange}
              placeholder="Title"
            />
            {formik.touched.title && formik.errors.title && (
              <ErrorField error={formik.errors.title} />
            )}
          </FormControl>
        </div>
        <div className={s.formField}>
          <Htag tag="h4">Reading time: </Htag>
          <FormControl fullWidth className={s.reading_time}>
            <input
              id="reading_time"
              name="reading_time"
              type="text"
              onBlur={formik.handleBlur}
              value={formik.values.reading_time}
              onChange={formik.handleChange}
              placeholder="Reading time"
            />
            {formik.touched.reading_time && formik.errors.reading_time && (
              <ErrorField error={formik.errors.reading_time} />
            )}
          </FormControl>
        </div>
        <div className={s.formField}>
          <Htag tag="h4" className={s.headline}>
            Upload template
          </Htag>

          <InputFileComponent
            files={attachmentsOld}
            handleFiles={handleAtachments}
            id="EdditRP"
            type="ticket"
          />
        </div>
        <div className={s.wyswyg_editorWrap}>
          <EditorComponent
            value={formik.values.description}
            handleChange={(e) => formik.setFieldValue("description", e)}
          />

          {formik.touched.description && formik.errors.description && (
            <ErrorField error={formik.errors.description} />
          )}
        </div>
        {system_ticket?.space_id == 1 && (
          <TextField
            variant="outlined"
            name="status"
            id="status"
            fullWidth
            select
            className={s.selectStatus}
            label={milestone === null ? "Task is…" : ""}
            defaultValue={milestone}
            InputLabelProps={{ shrink: false, disableAnimation: true }}
            value={milestone}
            onChange={handleStatusChange}
            sx={{
              "& .MuiSelect-iconOpen": { transform: "rotate(90deg)" },
              "& .MuiSelect-icon": { top: "16px" },
              "& .Mui-focused": {
                color: "#102D3E",
              },
              "& .Mui-focused.MuiOutlinedInput-root": {
                border: "2px solid #91CCD1;",
              },
            }}
            SelectProps={{
              IconComponent: (props) => <DropDownArrowIcon {...props} />,
            }}
          >
            {milestones.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
        {error &&
          Array.isArray(error) &&
          error.map((item, index) => (
            <ErrorField
              key={index}
              error={`Server error: ${Object.values(item)[0]}`}
            />
          ))}
        {error && typeof error == "string" && <ErrorField error={error} />}
        <p className={s.previewLink}>
          <NavLink
            target="_blank"
            to={`/admin-dashboard/system-tickets/edit-ticket/${id}/preview`}
          >
            Preview
          </NavLink>
        </p>
        <Button type="submit" apearance="secondary">
          Save
        </Button>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          System task: "{formik.values.title}" is updated!
        </Alert>
      </Snackbar>
    </>
  );
};
