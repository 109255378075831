import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAtlaState, IChatAnswer, IChatPrompt } from "./atla-interface";
import { atlaSendPrompt } from "./atla-thunk";

const initAtlaChat = {
  messages: [],
  loading: false,
  error: "",
};

const atlaState = createSlice({
  name: "@@atla",
  initialState: initAtlaChat,
  reducers: {
    setUserMessage: (state: IAtlaState, action: PayloadAction<IChatPrompt>) => {
      state.messages = [...state.messages, action.payload];
    },
    reset: () => initAtlaChat,
  },
  extraReducers: (builder) => {
    builder
      .addCase(atlaSendPrompt.pending, (state) => {
        state.loading = true;
      })
      .addCase(atlaSendPrompt.fulfilled, (state: IAtlaState, action) => {
        state.loading = false;
        const newAiMessage: IChatAnswer = {
          answer: action.payload,
          type: "bot",
        };
        state.messages = [...state.messages, newAiMessage];
      });
  },
});

export const { reset, setUserMessage } = atlaState.actions;

export default atlaState.reducer;
