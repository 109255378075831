import { NavLink, Outlet, useNavigate } from "react-router-dom";

import { DashboardLayoutProps } from "./DashboardLayout.props";
import s from "./DashboardLayout.module.css";
import cn from "classnames";
import { useEffect, useState } from "react";
import { createSocketConnection } from "routes/socketService";

import {
  setProducts,
  setSubscriptionData,
} from "store/userInfo/account-reducer";

import { GeneralOnboarding } from "./GeneralOnboarding/GeneralOnboarding.component";
import { FullModalStyled } from "components/FullWidthModal/FullModal.component";

import { getUserData } from "store/userInfo/user-thunk";
import {
  useAppDispatch,
  useAppSelector,
  useWindowScrollPositions,
} from "utils/hooks";
import { ISubscription } from "store/userInfo/user-interface";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  Button,
  GoToTop,
  LogoText,
  ModalStyled,
  Search,
  TopBar,
} from "components";

import { ForgotPass } from "./auth/ForgotPass/ForgotPass.component";
import {
  openLoginModal,
  openPassModal,
  resetToDefault,
} from "store/login/login-reducer";
import { Login } from "./auth/Login/Login.component";
import { ThreeDots } from "react-loader-spinner";

import { NavigationComponent } from "./Navigation/Navigation.component";

import { User } from "pages/Dashboard/User/User.component";
import { Footer } from "layout";
import {
  setBoardingStep,
  setRegModalStatus,
} from "store/onBoarding/onboarding-reducer";
import { userSidebarRoutes } from "routes/dashboard-routes";

export const DashboardLayout = (props: DashboardLayoutProps): JSX.Element => {
  const token = useAppSelector((state) => state.userState.token);
  const expired_date = useAppSelector((state) => state.userState.expired_at);
  const user_id = useAppSelector((state) => state.userState.user.id);
  const generalQuestionnary = useAppSelector(
    (state) => state.userState.user.questionnaires.generalQuestionnary
  );
  const [openNav, setOpenNav] = useState(false);

  const passwordModalStatus = useAppSelector(
    (state) => state.onLogin.openPassModal
  );
  const loginModalStatus = useAppSelector(
    (state) => state.onLogin.openLoginModal
  );

  const { scrollY } = useWindowScrollPositions();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  dayjs.extend(relativeTime);
  const subscriptionStatus = useAppSelector(
    (state) => state.accountState.subscription.status
  );
  const [loadDashboard, setLoadDashboard] = useState(false);

  useEffect(() => {
    if (expired_date) {
      const diference = dayjs(expired_date).diff(dayjs(Date.now()));

      if (diference !== undefined && diference < 0) {
        dispatch(openLoginModal(true));
        localStorage.removeItem("userToken");
        dispatch(resetToDefault());
      } else {
        const sInterval = setInterval(() => {
          dispatch(openLoginModal(true));
          localStorage.removeItem("userToken");
          dispatch(resetToDefault());
          clearInterval(sInterval);
        }, diference);
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (!token) dispatch(openLoginModal(true));
  }, [token]);

  useEffect(() => {
    if (token)
      try {
        createSocketConnection(token);
      } catch (error) {
        console.log(error);
      }
    if (!window.Echo) {
      dispatch(openLoginModal(true));
      return;
    }

    window.Echo.connector.pusher.connection.bind("connected", () => {
      console.log("connected");
    });

    window.Echo.connector.pusher.connection.bind("failed", (payload: any) => {
      console.log("failed", payload);
    });
    window.Echo.connector.pusher.connection.bind(
      "unavailable",
      (payload: any) => {
        console.log("unavailable", payload);
      }
    );
    window.Echo.connector.pusher.connection.bind(
      "disconnected",
      (payload: any) => {
        console.log("disconnected", payload);
      }
    );
  }, [token]);

  useEffect(() => {
    if (!window.Echo) return;
    window.Echo.join(`subscribe.${user_id}`) // subscribe.{user_id}
      .here((subscription: ISubscription[]) => {
        dispatch(
          setSubscriptionData({
            status: subscription[0].subscription.status,
            expired_date: subscription[0].subscription.date,
            sub_status: subscription[0].subscription.sub_status,
          })
        );

        dispatch(setProducts(subscription[0].products));
        if (
          !generalQuestionnary &&
          subscription[0].subscription.status === "active"
        ) {
          dispatch(getUserData())
            .unwrap()
            .then((res) => {
              if (!res.user.questionnaires.generalQuestionnary) {
                setOpenGeneralOB(true);
              }
            });
        }
        setLoadDashboard(true);
      })
      .listen("Subscribe", function (event: ISubscription[]) {
        console.log("2: subscribe");
        dispatch(
          setSubscriptionData({
            status: event[0].subscription.status,
            expired_date: event[0].subscription.date,
            sub_status: event[0].subscription.sub_status,
          })
        );
        if (!generalQuestionnary) {
          setOpenGeneralOB(true);
        }
      })
      .listen("Refund", function (event: ISubscription[]) {
        dispatch(
          setSubscriptionData({
            status: event[0].subscription.status,
            expired_date: event[0].subscription.date,
            sub_status: event[0].subscription.sub_status,
          })
        );
        dispatch(setProducts(event[0].products));
      })
      .listen("Unsubscribe", function (event: ISubscription[]) {
        dispatch(
          setSubscriptionData({
            status: event[0].subscription?.status,
            expired_date: event[0].subscription?.date,
            sub_status: event[0].subscription?.sub_status,
          })
        );
        dispatch(setProducts(event[0].products));
      })
      .error((error: any) => {
        if (error.type == "AuthError") dispatch(openLoginModal(true));
      });
  }, [token, generalQuestionnary, user_id]);

  const [openGeneralOB, setOpenGeneralOB] = useState(false);

  const handleClose = () => {
    setOpenGeneralOB(false);
  };

  const handleCloseLogout = () => {
    dispatch(openLoginModal(false));
    localStorage.removeItem("userToken");
    dispatch(resetToDefault());
    navigate("/");
  };

  const handleClosePass = () => {
    dispatch(openPassModal(false));
  };

  function deleteAllCookies() {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  // Code for the leave site button

  function hideSite() {
    window.open("https://en.wikipedia.org/wiki/Main_Page", "_newtab");
    location.replace("https://www.bbc.com/news");
    localStorage.removeItem("userToken");
    dispatch(resetToDefault());
  }

  const leaveSite = () => {
    hideSite();
    deleteAllCookies();
  };

  return (
    <div className={s.dashboardWrap} {...props}>
      <Button
        apearance="secondary"
        onClick={leaveSite}
        className={s.leaveSiteBtn}
      >
        Leave site
      </Button>

      <TopBar
        className={cn(
          {
            [s.sticky]: scrollY > 100,
          },
          s.topbar
        )}
      >
        <div className={s.dashboardBar}>
          <NavLink
            to={subscriptionStatus !== "active" ? "/" : "/dashboard"}
            className={cn(s.logo_link)}
          >
            <LogoText
              className={cn(s.logo, {
                [s.logo_link_light]: openNav,
              })}
            />
          </NavLink>

          <div className={s.topMobileNav}>
            {subscriptionStatus == "active" && (
              <input
                className={s.checkbox}
                type="checkbox"
                checked={openNav}
                onChange={() => setOpenNav((openNav) => !openNav)}
                name="menuTrigger"
                id="menuTrigger"
              />
            )}
            {subscriptionStatus == "active" && (
              <NavigationComponent
                nav={userSidebarRoutes}
                onClick={() => setOpenNav(false)}
                className={s.menu}
              />
            )}
            {subscriptionStatus == "active" && <Search />}
            {subscriptionStatus == "active" && (
              <div className={s.hamburger_lines}>
                <span className={cn(s.line, s.line1)}></span>
                <span className={cn(s.line, s.line2)}></span>
                <span className={cn(s.line, s.line3)}></span>
              </div>
            )}
            {subscriptionStatus !== "active" && loadDashboard && (
              <div className={s.becomeMember}>
                <Button
                  onClick={() => {
                    dispatch(setRegModalStatus(true));
                    dispatch(setBoardingStep(3));
                  }}
                  apearance="secondary"
                >
                  Become a member
                </Button>
              </div>
            )}
          </div>
        </div>
      </TopBar>
      <div className={s.dashboardContent}>
        {loadDashboard ? (
          subscriptionStatus == "active" && generalQuestionnary ? (
            <Outlet />
          ) : (
            <User />
          )
        ) : (
          <ThreeDots />
        )}
      </div>
      <Footer />
      <FullModalStyled open={openGeneralOB} close={handleClose} color={"light"}>
        <GeneralOnboarding close={handleClose} />
      </FullModalStyled>
      <FullModalStyled
        open={loginModalStatus}
        close={handleCloseLogout}
        color={"light"}
      >
        <Login title="You have been signed out." />
      </FullModalStyled>
      <FullModalStyled
        open={passwordModalStatus}
        color={"light"}
        close={handleClosePass}
      >
        <ForgotPass />
      </FullModalStyled>
      <GoToTop />
    </div>
  );
};
