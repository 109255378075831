import s from "./ForgotPass.module.css";
import { FormControl } from "@mui/material";

import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { Button, ErrorField, Htag, Icon1 } from "components";
import {
  changePassAction,
  forgotPassAction,
} from "store/login/forgotPass-thunk";
import { PasswordField } from "components/PasswordField/PasswordField.component";
const validationChangePassSchema = yup.object({
  code: yup
    .string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
      "Code should include only number"
    )
    .length(6, "Code should be 6 characters length")
    .required("Code is required"),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!+@#$%^&*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .min(8, "Password should be at least 8 characters length")

    .required("Password is required"),
  password_confirmation: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const initSendCode = {
  email: "",
};

const initChangePass = {
  code: "",
  password: "",
  password_confirmation: "",
};

const validationForgotSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

export const ForgotPass = (): JSX.Element => {
  const [successMsg, setSuccessMsg] = useState<string>();
  const [passChanged, setPassChanged] = useState<string>();
  const { error } = useAppSelector((state) => state.onLogin);

  const dispatch = useAppDispatch();

  const sendCode = useFormik({
    initialValues: initSendCode,
    validationSchema: validationForgotSchema,
    onSubmit: () => {
      dispatch(forgotPassAction(sendCode.values.email))
        .unwrap()
        .then((res) => {
          setSuccessMsg(res.message);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  });

  const changePass = useFormik({
    initialValues: initChangePass,
    validationSchema: validationChangePassSchema,
    onSubmit: (values) => {
      dispatch(changePassAction(values))
        .unwrap()
        .then((res) => {
          setPassChanged(res.message);
        })
        .catch((e) => console.log(e));
    },
  });

  return (
    <>
      <div className={s.loginWrapper}>
        <Htag tag="h2">Restore password</Htag>
        {successMsg ? (
          <>
            {!passChanged ? (
              <form onSubmit={changePass.handleSubmit} className={s.form_group}>
                <p>
                  Please, reset your password using the code we've emailed to
                  you.
                </p>
                <div className={s.form_oneline}>
                  <FormControl fullWidth>
                    <input
                      id="code"
                      name="code"
                      type="text"
                      placeholder="Code"
                      value={changePass.values.code}
                      onChange={changePass.handleChange}
                      onBlur={changePass.handleBlur}
                    />
                    {changePass.touched.code && changePass.errors.code ? (
                      <ErrorField error={changePass.errors.code} />
                    ) : null}
                  </FormControl>
                </div>
                <div className={s.form_oneline}>
                  <FormControl fullWidth>
                    <PasswordField
                      name="password"
                      placeholder="Password"
                      value={changePass.values.password}
                      handleChange={changePass.handleChange}
                      handleBlur={changePass.handleBlur}
                    />

                    {changePass.touched.password &&
                    changePass.errors.password ? (
                      <ErrorField error={changePass.errors.password} />
                    ) : null}
                  </FormControl>
                </div>
                <div className={s.form_oneline}>
                  <FormControl fullWidth>
                    <PasswordField
                      name="password_confirmation"
                      placeholder="Password Confirmation"
                      value={changePass.values.password_confirmation}
                      handleChange={changePass.handleChange}
                      handleBlur={changePass.handleBlur}
                    />

                    {changePass.touched.password_confirmation &&
                    changePass.errors.password_confirmation ? (
                      <ErrorField
                        error={changePass.errors.password_confirmation}
                      />
                    ) : null}
                  </FormControl>
                </div>
                {error ? <ErrorField error={error} /> : null}
                <div className={s.buttonGroup}>
                  <Button apearance="secondary" type="submit">
                    Change Password
                  </Button>
                </div>
              </form>
            ) : (
              <div className="success">{passChanged}</div>
            )}
          </>
        ) : (
          <form onSubmit={sendCode.handleSubmit} className={s.form_group}>
            <p>Please enter your email address and check your inbox:</p>
            <div className={s.form_oneline}>
              <FormControl fullWidth>
                <input
                  id="email"
                  name="email"
                  type="text"
                  placeholder="email"
                  value={sendCode.values.email}
                  onChange={sendCode.handleChange}
                  onBlur={sendCode.handleBlur}
                />
                {sendCode.touched.email && sendCode.errors.email ? (
                  <ErrorField error={sendCode.errors.email} />
                ) : null}
              </FormControl>
            </div>

            {error ? <span className={s.errorText}>{error}</span> : null}
            <div className={s.buttonGroup}>
              <Button apearance="secondary" type="submit">
                Send Code
              </Button>
            </div>
          </form>
        )}
      </div>
    </>
  );
};
