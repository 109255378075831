import { IBoard, ITicket } from "./tickets-interface";

export enum Statuses {
  TODO = 0,
  DONE = 1
}

export const nowT = (state: ITicket[]) =>
  state.filter((ticket) => ticket.status == Statuses.TODO).sort(sortArr);
export const nextT = (state: ITicket[]) =>
  state.filter((ticket) => ticket.status == Statuses.DONE).sort(sortArr);

const sortArr = (a: ITicket, b: ITicket) => {
  if (!a.sort || !b.sort) return 0;
  if (a.sort > b.sort) {
    return 1;
  }
  if (a.sort < b.sort) {
    return -1;
  }

  return 0;
};

export const getBoards = (state: ITicket[]) => {
  const boards: IBoard[] = [
    {
      id: 0,
      title: "Tasks",
      tickets: nowT(state),
    },
    {
      id: 1,
      title: "Completed tasks",
      tickets: nextT(state),
    },
  ];

  return boards;
};

export const getTamplates = (tickets: ITicket[]) => {

  return tickets
    .map((ticket) => {
      return ticket.attachments?.map((attach) => {
        if (ticket.special_ticket_id) {
          return {
            ...attach,
            special_ticket_id: ticket.special_ticket_id,
          };
        } else {
          return {
            ...attach,
            ticket_id: ticket.id,
          };
        }
      });
    })
    .flat(1);
};
