import s from "./Message.module.css";
import { MessageProps } from "./Message.props";

export const MessageComponent = ({ children, ...props }: MessageProps) => {
  return (
    <div {...props} className={s.msg}>
      {children}
    </div>
  );
};
