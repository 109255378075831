import { useEffect, useState } from "react";
import { Button, Card, Htag } from "components";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import s from "./Dashboard.module.css";
import { useNavigate } from "react-router-dom";
import { Milestone } from "store/milestones/milestones-interface";
import { loadMilestones } from "store/milestones/milestones-thunk";
import TimeLineItem from "components/TimelineItem/TimeLineItem.component";
import dayjs from "dayjs";
import cn from "classnames";
import { DashboardStepsInfo } from "components/DashboardStepsInfo/DashboardStepsInfo.component";

export const Dashboard = (): JSX.Element => {
  const navigate = useNavigate();
  const [milestones, setMilestones] = useState<Milestone[]>([]);
  const [unFinished, setUnfinished] = useState<Milestone>();

  const [firstLoginDiff, setFirstLoginDiff] = useState<undefined | number>();
  const has_children = useAppSelector(
    (state) =>
      state.userState.user.questionnaires.generalQuestionnary?.has_children
  );

  const name = useAppSelector((state) => state.userState.user.name);

  const firstLogin = localStorage.getItem("firstLogin");
  const dispatch = useAppDispatch();

  const findUnfinishedMilestone = (arr: Milestone[]) => {
    const value = Object.assign(
      {},
      arr.find((item) => item.complete_percent !== 100)
    );
    return value;
  };

  useEffect(() => {
    if (firstLogin) {
      const day = dayjs(firstLogin);
      const diff = day.diff(new Date(), "day");
      setFirstLoginDiff(diff);
    } else {
      localStorage.setItem("firstLogin", new Date().toString());
    }
  }, []);

  useEffect(() => {
    dispatch(loadMilestones())
      .unwrap()
      .then((res: Milestone[]) => {
        setMilestones(res);
        setUnfinished(findUnfinishedMilestone(res));
      });
  }, []);

  return (
    <>
      <header className={s.dashboardHeader}>
        <Htag tag="h1">
          Welcome {firstLoginDiff ? "back" : ""}, {name}
        </Htag>
      </header>
      <section className={s.milestonesWrap}>
        <Htag tag="h3">Here is your divorce pathway</Htag>
        <div className={s.milestones}>
          {milestones.map((milestone, index) => (
            <TimeLineItem
              index={index + 1}
              id={milestone.id.toString()}
              title={milestone.title}
              key={index}
              completed_percent={milestone.complete_percent}
            />
          ))}
        </div>
      </section>
      <DashboardStepsInfo milestone={unFinished} />

      <Card
        color="var(--modal-gray)"
        className={cn(s.text_left, s.actionCards)}
      >
        <Htag tag="h3">Book your free 15 minute intro call</Htag>
        <Button
          onClick={() => navigate("/dashboard/divorce-expert?intro_call")}
          apearance="default"
        >
          Book video call
        </Button>
      </Card>
      {has_children == "yes" && (
        <Card
          color="var(--modal-gray)"
          className={cn(s.text_left, s.actionCards)}
        >
          <Htag tag="h3">Focus on parenting</Htag>
          <div className={s.stepIntroText}>
            <img src="../assets/images/Vic.png" alt="" />
            We’ve designed a special set of tasks related to parenting during
            divorce. It includes tools to help you reach an agreement for
            dividing time with your children, templates, and more.
          </div>
          <Button
            onClick={() => navigate("/dashboard/space/2")}
            apearance="default"
          >
            Parenting resources
          </Button>
        </Card>
      )}
      <Card
        color="var(--modal-gray)"
        className={cn(s.text_left, s.actionCards)}
      >
        <Htag tag="h3">Atla AI</Htag>
        <div className={s.stepIntroText}>
          <img src="../assets/images/atla.png" alt="" />A chatbot which can help
          you through the divorce process, offer tips on practicalities,
          supporting children and handling finances. It can point you to useful
          templates and resources.
        </div>
        <Button
          onClick={() => navigate("/dashboard/atlaAI")}
          apearance="default"
        >
          Ask Atla AI
        </Button>
      </Card>
      <Card
        color="var(--modal-gray)"
        className={cn(s.text_left, s.actionCards)}
      >
        <Htag tag="h3">Prioritise your wellbeing</Htag>
        <div className={s.stepIntroText}>
          <img src="../assets/images/Vic2.png" alt="" />
          Guides to effective communication, resolving conflict, and managing
          emotions written by relationship experts and psychologists.
        </div>
        <Button
          onClick={() => navigate("/dashboard/my-wellbeing")}
          apearance="default"
        >
          Wellbeing resources
        </Button>
      </Card>

      <Card
        color="var(--modal-gray)"
        className={cn(s.text_left, s.actionCards)}
      >
        <Htag tag="h3">Divorce experts</Htag>
        <div className={s.stepIntroText}>
          <img src="../assets/images/cady.png" alt="" />
          Every divorce is different. Talk specifics with our divorce experts at
          a discounted rate. Book a 60-minute private video call for £195 to get
          your questions answered.
        </div>
        <Button onClick={() => navigate("divorce-expert")} apearance="default">
          Book video call
        </Button>
      </Card>
    </>
  );
};
