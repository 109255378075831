import {
  ICaseStudies,
  IIssue,
  IOption,
  ISummary,
} from "../store/caseStudy/caseStudy.inderface";
import { Article } from "./Article.model";

export class CaseStudy extends Article implements ICaseStudies {
  image: Blob | null | string;
  private _unique_number: string;
  subtitle: string;
  summary: ISummary[];
  options: IOption[] | undefined[];
  problems: IIssue[];
  bookmark: boolean;

  constructor(
    title = "",
    content = "",
    reading_time = "",
    image = "",
    unique_number = "",
    subtitle = "",
    summary = [],
    options = [],
    problems = []
  ) {
    super(title, content, reading_time);
    this.image = image;
    this.unique_number = unique_number;
    this.subtitle = subtitle;
    this.summary = summary;
    this.options = options;
    this.problems = problems;
  }

  get unique_number() {
    return this._unique_number;
  }

  set unique_number(unique_number) {
    this._unique_number = unique_number;
  }


 
}
